<template>
  <div class="containerCanvas">
    <!-- <span>{{current.x}},{{current.y}}</span> -->
    <template v-if="!onlyView">
      <div class="toolDrawer">
        <div style="display: inline-block">
          <label style="display: block; font-size: 13px; color: white"
            >mode</label
          >
          <a-select style="width: 125px" v-model="toolDrawer">
            <a-select-option value="pencil">pencil</a-select-option>
            <a-select-option value="rect">rectangle</a-select-option>
            <a-select-option value="circ">circle</a-select-option>
            <a-select-option value="triangle">triangle</a-select-option>
            <a-select-option value="eraser">eraser</a-select-option>
          </a-select>
        </div>
        <template v-if="toolDrawer !== 'eraser'">
          <div style="display: inline-block" class="mx-3">
            <label
              class="mb-2"
              style="display: block; font-size: 13px; color: white"
              >color</label
            >
            <input type="color" v-model="current.color" />
          </div>
          <div v-if="toolDrawer === 'pencil'" style="display: inline-block">
            <label style="display: block; font-size: 13px; color: white"
              >stroke width</label
            >
            <a-input-number type="number" v-model="current.strokeWidth" />
          </div>
        </template>
      </div>
    </template>
    <div class="otherOptionDrawer">
      <div class="syncDbDrawer mr-3">
        <a-icon
          @click.prevent="syncDbDrawer"
          type="sync"
          style="color: #038080; font-size: 20px"
        />
      </div>
      <div v-if="!onlyView" class="deleteCanvasContent">
        <a-icon
          @click.prevent="deleteDrawer"
          type="delete"
          style="color: #038080; font-size: 20px"
        />
      </div>
    </div>
    <v-stage
      v-if="onlyView"
      ref="stage"
      :config="configDrawer"
      class="whiteboard"
    >
      <v-layer ref="layer" style="touch-action: none">
        <v-line
          v-for="(line, i) in drawObj.lines"
          :key="'line-' + i"
          :config="line"
        />
        <v-rect
          v-for="(rect, j) in drawObj.rects"
          :key="'rect-' + j"
          :config="rect"
          @transformend="handleTransformEnd"
        />
        <v-circle
          v-for="(circ, k) in drawObj.circles"
          :key="'circ-' + k"
          :config="circ"
          @transformend="handleTransformEnd"
        />
        <v-regular-polygon
          v-for="(tri, l) in drawObj.triangles"
          :key="'tri-' + l"
          :config="tri"
          @transformend="handleTransformEnd"
        />
      </v-layer>
    </v-stage>
    <v-stage
      v-else
      @mousedown="beginDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @touchstart="beginDrawing"
      @touchmove="draw"
      @touchend="stopDrawing"
      @click="drawShape"
      ref="stage"
      :config="configDrawer"
      class="whiteboard"
    >
      <v-layer ref="layer" style="touch-action: none">
        <v-line
          v-for="(line, i) in drawObj.lines"
          :key="'line-' + i"
          :config="line"
        />
        <v-rect
          v-for="(rect, j) in drawObj.rects"
          :key="'rect-' + j"
          :config="rect"
          @dragend="handleTransformEnd"
          @transformend="handleTransformEnd"
        />
        <v-circle
          v-for="(circ, k) in drawObj.circles"
          :key="'circ-' + k"
          :config="circ"
          @dragend="handleTransformEnd"
          @transformend="handleTransformEnd"
        />
        <v-regular-polygon
          v-for="(tri, l) in drawObj.triangles"
          :key="'tri-' + l"
          :config="tri"
          @transformend="handleTransformEnd"
          @dragend="handleTransformEnd"
        />
        <v-transformer ref="transformer" />
      </v-layer>
    </v-stage>
  </div>
</template>
<script>
import socket from '@/socket.js'
import $ from 'jquery'
export default {
  props: ['config', 'isCollaborate', 'onlyView'],
  data() {
    return {
      current: {
        shapeX0: 0,
        shapeY0: 0,
        x: 0,
        y: 0,
        color: '#000000',
        strokeWidth: 2,
      },
      drawerRefs: {},
      idSession: this.$route.params.id,
      toolDrawer: 'pencil',
      isDrawingShape: false,
      isDrawingLine: false,
      dataHistory: [],
      configDrawer: {},
      drawObj: {
        lines: [],
        rects: [],
        circles: [],
        triangles: [],
      },
      selectedShapeName: '',
    }
  },
  mounted() {
    this.drawerRefs = {
      stage: this.$refs.stage.getNode(),
      layer: this.$refs.layer.getNode(),
    }
    const w = $('.containerCanvas').parent().width()
    const h = $('.containerCanvas').parent().height()
    // console.log(w, h)
    this.configDrawer = {
      // width: $('.liveBox').width() - ($('.liveBox').width() * 20 / 100),
      // height: $('.liveBox').height(),
      // width: '80%',
      width: w,
      height: h,
    }
    socket.on('drawing', this.onDrawing)
    socket.on('delete-all-drawer', (isCollaborate = false) => {
      if (isCollaborate === this.isCollaborate) {
        this.deleteDrawerLocal()
      }
    })
    socket.emit('join-session', `session:${this.idSession}`)
    socket.on('join-session', data => {
      if (data) {
        const width = this.configDrawer.width
        const height = this.configDrawer.height
        let newData = data[this.isCollaborate ? 'lastDrawingCol' : 'lastDrawing']
        newData = JSON.parse(newData)
        let newLines = newData.lines
        newLines = newLines.map((line, i) => {
          line.points = line.points.map((point, i) => {
            if (i % 2 === 0) {
              return point * width
            } else {
              return point * height
            }
          })

          return line
        })
        this.drawObj.lines = newLines
        this.drawObj.rects = newData.rects.map(rect => {
          return {
            ...rect,
            width: rect.width * width,
            x: rect.x * width,
            height: rect.height * height,
            y: rect.y * height,
            draggable: !this.onlyView,
          }
        })
        this.drawObj.circles = newData.circles.map(circ => {
          return {
            ...circ,
            x: circ.x * width,
            y: circ.y * height,
            radius: Number(circ.radius) * width,
            draggable: !this.onlyView,
          }
        })
        this.drawObj.triangles = newData.triangles.map(tri => {
          return {
            ...tri,
            x: tri.x * width,
            y: tri.y * height,
            radius: Number(tri.radius) * width,
            draggable: !this.onlyView,
          }
        })
        // for (let i = 0; i < this.dataHistory.length; i++) {
        //   const draw = this.dataHistory[i]
        //   this.drawLine(
        //     Number(draw.x1) * width, Number(draw.y1) * height,
        //     {
        //       x: Number(draw.x) * width,
        //       y: Number(draw.y) * height,
        //       color: draw.color,
        //       strokeWidth: draw.strokeWidth,
        //       toolDrawer: draw.toolDrawer,
        //     },
        //   )
        // }
      }
    })

    socket.on('sync', data => {
      // console.log(data)
      if (this.isCollaborate === data.isCollaborate) {
        for (let i = 0; i < data.drawing.lines.length; i++) {
          const line = data.drawing.lines[i]
          // console.log(line.points)
          line.points = line.points.map((point, j) => {
            if (j % 2 === 0) {
              return Number(point) * data.width
            } else {
              return Number(point) * data.height
            }
          })
          // console.log(line.points)
        }

        data.drawing.rects = data.drawing.rects.map(rect => {
          return {
            ...rect,
            width: rect.width * data.width,
            x: rect.x * data.width,
            height: rect.height * data.height,
            y: rect.y * data.height,
          }
        })

        data.drawing.circles = data.drawing.circles.map(circ => {
          return {
            ...circ,
            x: circ.x * data.width,
            y: circ.y * data.height,
            radius: Number(circ.radius) * data.width,
          }
        })

        data.drawing.triangles = data.drawing.triangles.map(tri => {
          return {
            ...tri,
            x: tri.x * data.width,
            y: tri.y * data.height,
            radius: Number(tri.radius) * data.width,
          }
        })
        // console.log(data.drawing)
        this.drawObj = data.drawing
      }
    })
  },
  watch: {
    'current.strokeWidth'(newVal) {
      if (newVal > 10) {
        this.current.strokeWidth = 10
      } else if (newVal < 1) {
        this.current.strokeWidth = 1
      }
    },
  },
  methods: {
    syncDbDrawer(e) {
      const w = this.configDrawer.width
      const h = this.configDrawer.height
      socket.emit('sync', {
        session: `session:${this.$route.params.id}`,
        isCollaborate: this.isCollaborate,
        width: w,
        height: h,
      })
    },
    handleTransformEnd(e) {
      let data = {}
      const payload = {}
      const code = this.selectedShapeName.split('-')[0]
      let key = ''
      if (code === 'rect') {
        data = this.drawObj.rects.find(
          (r) => r.name === this.selectedShapeName,
        )
        key = 'rects'
      } else if (code === 'circ') {
        payload.circles = {}
        data = this.drawObj.circles.find(
          (r) => r.name === this.selectedShapeName,
        )
        key = 'circles'
      } else if (code === 'tri') {
        payload.triangles = {}
        data = this.drawObj.triangles.find(
          (r) => r.name === this.selectedShapeName,
        )
        key = 'triangles'
      }

      if (!data) {
        this.selectedShapeName = ''
        this.updateTransformer()
        return
      }
      // console.log(data)
      // update the state
      data.x = e.target.x()
      data.y = e.target.y()
      data.rotation = e.target.rotation()
      data.scaleX = e.target.scaleX()
      data.scaleY = e.target.scaleY()
      payload[key] = data
      // console.log(payload, 'masuk', e.target.x())
      const w = this.configDrawer.width
      const h = this.configDrawer.height
      // console.log(w, h)
      socket.emit('drawing', {
        session: `session:${this.$route.params.id}`,
        color: this.current.color,
        strokeWidth: this.current.strokeWidth,
        toolDrawer: this.toolDrawer,
        endDrawing: true,
        dataDrawing: payload,
        isCollaborate: this.isCollaborate,
        width: w,
        height: h,
        isTransform: true,
      })
    },
    drawShape(e) {
      if (this.selectedShapeName !== '') {
        this.isDrawingShape = !this.isDrawingShape
        return
      }

      const x = e.evt.layerX
      const y = e.evt.layerY
      // console.log(e, x, y)
      const w = this.configDrawer.width
      const h = this.configDrawer.height

      if (this.isDrawingShape) {
        this.isDrawingShape = !this.isDrawingShape
        const data = {}
        if (this.toolDrawer === 'rect') {
          data.rects = this.drawObj.rects.length ? this.drawObj.rects[this.drawObj.rects.length - 1] : []
        } else if (this.toolDrawer === 'circ') {
          data.circles = this.drawObj.circles.length ? this.drawObj.circles[this.drawObj.circles.length - 1] : []
        } else if (this.toolDrawer === 'triangle') {
          // console.log(this.drawObj.triangles, 'ini')
          data.triangles = this.drawObj.triangles.length ? this.drawObj.triangles[this.drawObj.triangles.length - 1] : []
        }
        // console.log(x, y, '1')
        socket.emit('drawing', {
          session: `session:${this.$route.params.id}`,
          color: this.current.color,
          strokeWidth: this.current.strokeWidth,
          toolDrawer: this.toolDrawer,
          endDrawing: true,
          dataDrawing: data,
          isCollaborate: this.isCollaborate,
          width: w,
          height: h,
          isTransform: false,
        })
      } else {
        this.isDrawingShape = true

        if (this.toolDrawer === 'rect') {
          this.drawObj.rects.push({
            rotation: 0,
            x,
            y,
            width: 0,
            height: 0,
            fill: this.current.color,
            name: 'rect-' + (this.drawObj.rects.length + 1),
            draggable: true,
          })
        // console.log(x, y, '1')
        } else if (this.toolDrawer === 'circ') {
          this.drawObj.circles.push({
            rotation: 0,
            x,
            y,
            radius: 0,
            fill: this.current.color,
            name: 'circ-' + (this.drawObj.circles.length + 1),
            draggable: true,
          })
        } else if (this.toolDrawer === 'triangle') {
          // console.log(this.drawObj.triangles)
          this.drawObj.triangles.push({
            rotation: 0,
            x,
            y,
            sides: 3,
            radius: 0,
            fill: this.current.color,
            name: 'tri-' + (this.drawObj.triangles.length + 1),
            draggable: true,
          })
          // console.log(this.drawObj.triangles)
        }
        // console.log(x, y, '2')
      }
    },
    deleteDrawerLocal() {
      const context = this.drawerRefs.layer.canvas.context
      const w = this.configDrawer.width
      const h = this.configDrawer.height
      context.clearRect(0, 0, w, h)
      this.selectedShapeName = ''
      this.updateTransformer()
      this.drawObj = {
        lines: [],
        rects: [],
        circles: [],
        triangles: [],
      }
    },
    deleteDrawer(e) {
      this.deleteDrawerLocal()
      // console.log(this.isCollaborate)
      socket.emit('delete-all-drawer', {
        isCollaborate: this.isCollaborate,
        session: this.$route.params.id,
      })
    },
    onDrawing(coord) {
      if (coord.isCollaborate === this.isCollaborate) { // check if socket data equal with internal isCollaborate data
        const w = this.configDrawer.width
        const h = this.configDrawer.height
        if (this.drawerRefs.layer.canvas.context) {
          if (coord.dataDrawing.lines) {
            const normalizedPoint = coord.dataDrawing.lines.points.map((point, i) => {
              if (i % 2 === 0) {
                return Number(point * w)
              } else {
                return Number(point * h)
              }
            })

            coord.dataDrawing.lines.points = normalizedPoint
            // console.log(this.drawObj.lines, '1', coord.dataDrawing)
            this.drawObj.lines = this.drawObj.lines.concat([coord.dataDrawing.lines])
          } else if (coord.dataDrawing.rects) {
            // console.log(coord.dataDrawing.rects)
            if (!this.drawObj.rects.length) {
              this.drawObj.rects = this.drawObj.rects.concat([{
                ...coord.dataDrawing.rects,
                width: coord.dataDrawing.rects.width * w,
                height: coord.dataDrawing.rects.height * h,
                x: coord.dataDrawing.rects.x * w,
                y: coord.dataDrawing.rects.y * h,
                draggable: !this.onlyView,
              }])
            } else {
              const newData = coord.dataDrawing.rects
              const name = newData.name
              // let newRect = {}
              for (let i = 0; i < this.drawObj.rects.length; i++) {
                if (this.drawObj.rects[i].name === name) {
                  // console.log(rect, newData, 'lama')
                  // console.log(newData.x * w, this.drawObj.rects[i])
                  this.drawObj.rects[i] = {
                    ...newData,
                    width: newData.width * w,
                    height: newData.height * h,
                    x: newData.x * w,
                    y: newData.y * h,
                    draggable: !this.onlyView,
                  }
                  break
                }

                if (i === this.drawObj.rects.length - 1 && this.drawObj.rects[i].name !== name) {
                  this.drawObj.rects = this.drawObj.rects.concat([{
                    ...coord.dataDrawing.rects,
                    width: coord.dataDrawing.rects.width * w,
                    height: coord.dataDrawing.rects.height * h,
                    x: coord.dataDrawing.rects.x * w,
                    y: coord.dataDrawing.rects.y * h,
                    draggable: !this.onlyView,
                  }])
                }
              }
            }
            // console.log(this.drawObj.lines, '2', coord.dataDrawing)
            // this.drawLine(coord.x2 * w, coord.y2 * h,
            //   { x: coord.x1 * w, y: coord.y1 * h, color: coord.color, strokeWidth: coord.strokeWidth, toolDrawer: coord.toolDrawer },
            //   false, false)
          } else if (coord.dataDrawing.circles) {
            if (!this.drawObj.circles.length) {
              this.drawObj.circles = this.drawObj.circles.concat([{
                ...coord.dataDrawing.circles,
                radius: Number(coord.dataDrawing.circles.radius) * w,
                x: coord.dataDrawing.circles.x * w,
                y: coord.dataDrawing.circles.y * h,
                draggable: !this.onlyView,
              }])
            } else {
              const newData = coord.dataDrawing.rects
              const name = coord.dataDrawing.circles.name
              // let newRect = {}
              for (let i = 0; i < this.drawObj.circles.length; i++) {
                let circ = this.drawObj.circles[i]
                if (circ.name === name) {
                  circ = {
                    ...newData,
                    x: newData.x * w,
                    y: newData.y * h,
                    radius: Number(newData.radius) * w,
                    draggable: !this.onlyView,
                  }
                  break
                }

                if (i === this.drawObj.circles.length - 1 && circ.name !== name) {
                  this.drawObj.circles = this.drawObj.circles.concat([{
                    ...coord.dataDrawing.circles,
                    radius: Number(coord.dataDrawing.circles.radius) * w,
                    x: coord.dataDrawing.circles.x * w,
                    y: coord.dataDrawing.circles.y * h,
                    draggable: !this.onlyView,
                  }])
                }
              }
            }
          } else if (coord.dataDrawing.triangles) {
            if (!this.drawObj.triangles.length) {
              this.drawObj.triangles = this.drawObj.triangles.concat([{
                ...coord.dataDrawing.triangles,
                radius: Number(coord.dataDrawing.triangles.radius) * w,
                x: coord.dataDrawing.triangles.x * w,
                y: coord.dataDrawing.triangles.y * h,
                draggable: !this.onlyView,
              }])
            } else {
              const newData = coord.dataDrawing.triangles
              const name = coord.dataDrawing.triangles.name
              // let newRect = {}
              for (let i = 0; i < this.drawObj.triangles.length; i++) {
                let triangle = this.drawObj.triangles[i]
                if (triangle.name === name) {
                  // console.log(triangle)
                  triangle = {
                    ...newData,
                    radius: Number(newData.radius) * w,
                    x: newData.x * w,
                    y: newData.y * h,
                    draggable: !this.onlyView,
                  }
                  break
                }

                if (i === this.drawObj.triangles.length - 1 && triangle.name !== name) {
                  this.drawObj.triangles = this.drawObj.triangles.concat([{
                    ...coord.dataDrawing.triangles,
                    radius: Number(coord.dataDrawing.triangles.radius) * w,
                    x: coord.dataDrawing.triangles.x * w,
                    y: coord.dataDrawing.triangles.y * h,
                    draggable: !this.onlyView,
                  }])
                }
              }
            }
          }

          if (coord.isTransform) {
            socket.emit('sync', {
              session: `session:${this.$route.params.id}`,
              isCollaborate: this.isCollaborate,
              width: coord.width,
              height: coord.height,
            })
          }
        }
      }
    },
    beginDrawing(e) {
      // console.log('masuk')
      const x = this.drawerRefs.stage.getPointerPosition().x
      const y = this.drawerRefs.stage.getPointerPosition().y
      if (!e.target.name() && this.toolDrawer !== 'pencil' && this.toolDrawer !== 'eraser' && !this.isDesktop) {
        e.evt.layerX = x
        e.evt.layerY = y
        // console.log('masuk sini', e.evt)
        return this.drawShape(e)
      }
      this.current.x = x
      this.current.y = y
      // console.log(e.target.name())
      if (!e.target.name()) {
        this.selectedShapeName = ''
        this.updateTransformer()
        this.drawObj.lines.push({
          id: new Date(),
          stroke: this.current.color,
          strokeWidth: this.toolDrawer === 'eraser' ? 8 : this.current.strokeWidth,
          globalCompositeOperation: this.toolDrawer === 'pencil' ? 'source-over' : 'destination-out',
          points: [x, y],
        })
        this.isDrawingLine = true
      } else {
        if (e.target === e.target.getStage()) {
          // console.log('masuk')
          this.selectedShapeName = ''
          this.updateTransformer()
          return
        }

        const clickedOnTransformer =
        e.target.getParent().className === 'Transformer'
        if (clickedOnTransformer) {
          // console.log('masuk sini')
          return
        }

        const name = e.target.name()
        // const code = e.target.name().split('-')[0]
        const rect = this.drawObj.rects.find((r) => r.name === name)
        const circ = this.drawObj.circles.find((r) => r.name === name)
        const tri = this.drawObj.triangles.find((r) => r.name === name)
        if (rect || circ || tri) {
          this.selectedShapeName = name
        } else {
          this.selectedShapeName = ''
        }
        this.updateTransformer()
      }
    },
    updateTransformer() {
      // if (code === 'rect') {

      // } else if (code === ' circ') {

      // } else if (code === 'tri') {

      // }
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      const { selectedShapeName } = this
      const selectedNode = stage.findOne('.' + selectedShapeName)
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode])
      } else {
        // remove transformer
        transformerNode.nodes([])
      }
      transformerNode.getLayer().batchDraw()
    },
    draw(e) {
      const w = this.configDrawer.width
      const h = this.configDrawer.height
      if (this.isDrawingLine) {
        const x = this.drawerRefs.stage.getPointerPosition().x
        const y = this.drawerRefs.stage.getPointerPosition().y
        if (x < 0 || x > w || y < 0 || y > h) {
          this.stopDrawing()
        }
        // this.dataHistory.push({ x1: this.current.x / w, y1: this.current.y / h, x: x / w, y: y / h, color: this.toolDrawer === 'eraser' ? '#ffffff' : this.current.color, strokeWidth: this.current.strokeWidth, toolDrawer: this.toolDrawer })
        const newPoints = this.drawObj.lines[this.drawObj.lines.length - 1].points.concat([x, y])
        this.drawObj.lines[this.drawObj.lines.length - 1].points = newPoints
        // if (this.toolDrawer === 'eraser' || this.toolDrawer === 'pencil') {
        //   this.drawLine(x, y, { ...this.current, toolDrawer: this.toolDrawer }, true, false)
        // }
        this.current.x = x
        this.current.y = y
        const lines = [...this.drawObj.lines]
        const newLine = lines[lines.length - 1]

        socket.emit('drawing', {
          session: `session:${this.$route.params.id}`,
          color: this.current.color,
          strokeWidth: this.current.strokeWidth,
          toolDrawer: this.toolDrawer,
          endDrawing: false,
          dataDrawing: {
            lines: newLine,
          },
          isCollaborate: this.isCollaborate,
          width: w,
          height: h,
          isTransform: false,
        })
        // console.log('ote')
      } else if (this.isDrawingShape && this.selectedShapeName === '') {
        const x = e.evt.layerX || this.drawerRefs.stage.getPointerPosition().x
        const y = e.evt.layerY || this.drawerRefs.stage.getPointerPosition().y
        // console.log(x, y, e)
        if (this.toolDrawer === 'rect') {
          const rects = this.drawObj.rects
          const shape = rects[rects.length - 1]
          // console.log(shape)
          const currWidth = x - shape.x
          const currHeight = y - shape.y
          const newShapes = rects.slice()
          // console.log(newShapes)
          newShapes[rects.length - 1] = {
            ...newShapes[rects.length - 1],
            width: currWidth,
            height: currHeight,
          }

          this.drawObj.rects = newShapes
        } else if (this.toolDrawer === 'circ') {
          const circles = this.drawObj.circles
          const shape = circles[circles.length - 1]
          const currWidth = x - shape.x
          // console.log(shape)
          // const currHeight = y - shape.y
          const newShapes = circles.slice()
          // console.log(newShapes)
          newShapes[circles.length - 1] = {
            ...newShapes[circles.length - 1],
            radius: Math.abs(currWidth),
          }

          this.drawObj.circles = newShapes
        } else if (this.toolDrawer === 'triangle') {
          const triangles = this.drawObj.triangles
          const shape = triangles[triangles.length - 1]
          const currWidth = x - shape.x
          // console.log(shape)
          // const currHeight = y - shape.y
          const newShapes = triangles.slice()
          // console.log(newShapes)
          newShapes[triangles.length - 1] = {
            ...newShapes[triangles.length - 1],
            radius: Math.abs(currWidth),
          }

          this.drawObj.triangles = newShapes
        }
      }
    },
    stopDrawing(e) {
      if (this.isDrawingLine) {
        this.isDrawingLine = false
        const w = this.configDrawer.width
        const h = this.configDrawer.height
        const lines = this.drawObj.lines.slice()
        const newLine = lines[lines.length - 1]
        socket.emit('drawing', {
          session: `session:${this.$route.params.id}`,
          color: this.current.color,
          strokeWidth: this.current.strokeWidth,
          toolDrawer: this.toolDrawer,
          endDrawing: true,
          dataDrawing: {
            lines: newLine,
          },
          isCollaborate: this.isCollaborate,
          width: w,
          height: h,
          isTransform: false,
        })
        // if (this.toolDrawer === 'eraser' || this.toolDrawer === 'pencil') {
        //   this.drawLine(x, y, { ...this.current, toolDrawer: this.toolDrawer, dataDrawing: this.dataHistory, dataDrawingCol: this.dataHistoryCol }, true, true)
        // }
        // console.log('s')
        // const canvas = this.drawerRefs.layer.canvas
        // const data = canvas.toDataURL('image/png')
        // localStorage.setItem('drawing', data)
        // this.saveLastDrawing()
        // this.current.x = 0
        // this.current.y = 0
      }
    },
    drawLine(x2, y2, currentProps, emit, endDrawing = false) {
      const context = this.drawerRefs.layer.canvas.context
      context.beginPath()
      context.strokeStyle = currentProps.toolDrawer === 'eraser' ? '#ffffff' : currentProps.color
      context.lineWidth = currentProps.toolDrawer === 'eraser' ? 8 : currentProps.strokeWidth
      context.moveTo(currentProps.x, currentProps.y)
      context.lineTo(x2, y2)
      context.stroke()
      context.closePath()

      const w = this.configDrawer.width
      const h = this.configDrawer.height

      if (emit) {
        // const layer = this.drawerRefs.layer
        // const canvas = this.drawerRefs.layer.canvas
        // const data = canvas.toDataURL('image/png')
        // const data = this.drawerRefs.stage.toJSON()
        // console.log(data)
        socket.emit('drawing', {
          x1: currentProps.x / w,
          y1: currentProps.y / h,
          x2: x2 / w,
          y2: y2 / h,
          session: `session:${this.$route.params.id}`,
          color: currentProps.color,
          strokeWidth: currentProps.strokeWidth,
          toolDrawer: this.toolDrawer,
          endDrawing,
          dataDrawing: this.dataHistory,
          isCollaborate: this.isCollaborate,
          isTransform: false,
        })
      }

      // if (this.isDrawing) {
      //   this.saveLastDrawing()
      // }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
<style lang="scss">
.containerCanvas {
  width: 100%;
  height: 100%;
  .konvajs-content {
    canvas {
      touch-action: none;
    }
  }
  .toolDrawer {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    padding: 10px;
    border-radius: 10px;
    background-color: #038080 !important;
    z-index: 2000;
    input {
      margin-right: 5px;
    }
  }
  .otherOptionDrawer {
    position: absolute;
    margin: 10px;
    right: 0;
    z-index: 101;
    display: flex;
    .syncDbDrawer {
      border-radius: 100%;
      &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
      }
    }
    .deleteCanvasContent {
      border-radius: 100%;

      &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
      }
    }
  }

  .whiteboard {
    // height: 100%;
    // width: 100%;
    // position: relative;
    // border: 1px solid black;
    cursor: url("/src/assets/pencil.png");
    // left: 0;
    // right: 0;
    // bottom: 0;
    // top: 0;
  }
}
</style>
